/*
Theme Name: Entre Ambos Theme
Author: Iquadrat
Author URI: http://www.iquadrat.com/
Version: 1.0
*/

/*** IQ PREDEFINED STYLES ***/

* {
  outline: none !important;
}
body {
  font-family: "Merriweather", serif;
  font-size: 14px;
  color: #333333;
}
a {
  color: #333333;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
  color: #333333;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.text-black,
.text-black *,
.text-black * {
  color: #333333 !important;
}
.text-red {
  color: #ec0928 !important;
}

.fs10 {
  font-size: 0.625rem;
}
.fs12 {
  font-size: 0.75rem;
}
.fs14 {
  font-size: 0.875rem;
}
.fs16 {
  font-size: 1rem;
}
.fs18 {
  font-size: 1.125rem;
}
.fs20 {
  font-size: 1.25rem;
}
.fs22 {
  font-size: 1.375rem;
}
.fs24 {
  font-size: 1.5rem;
}
.fs26 {
  font-size: 1.625rem;
}
.fs28 {
  font-size: 1.75rem;
}
.fs30 {
  font-size: 1.875rem;
}
.fs32 {
  font-size: 2rem;
}

.lh14 {
  line-height: 0.875rem;
}
.lh16 {
  line-height: 1rem;
}
.lh18 {
  line-height: 1.125rem;
}
.lh20 {
  line-height: 1.25rem;
}
.lh22 {
  line-height: 1.375rem;
}
.lh24 {
  line-height: 1.5rem;
}
.lh26 {
  line-height: 1.625rem;
}
.lh28 {
  line-height: 1.75rem;
}
.lh30 {
  line-height: 1.875rem;
}
.lh32 {
  line-height: 2rem;
}
.lh40 {
  line-height: 2.75rem;
}

.lSpace100 {
  letter-spacing: 0.08rem;
}
.lSpace50 {
  letter-spacing: 0.05rem;
}
.lSpace25 {
  letter-spacing: 0.02rem;
}

.semiBold {
  font-weight: 600;
}

.nounderline {
  text-decoration: none;
}
.nounderline:hover {
  text-decoration: underline;
}

.noboxshadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}

.grayImg {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/*** HEADER ***/

header #bar {
  height: 95px;
  width: 100%;
}
header a {
  color: #333333;
  text-decoration: none;
}
header a:hover {
  color: #333333;
}
header ul.menu {
  font-family: "Montserrat", sans-serif !important;
}
header ul.menu,
header ul.menu ul.sub-menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
header ul.menu > li {
  float: none;
  display: inline-block;
  position: relative;
  padding: 0.3rem 0.6rem;
}
header ul.menu > li > a {
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  font-weight: 700 !important;
  font-size: 0.75rem;
  height: 100%;
  width: 100%;
}
header ul.menu > li:hover {
  background-color: #f6f6f6;
}
header ul.menu li:hover ul.sub-menu {
  display: block;
}
header ul.menu ul.sub-menu {
  display: none;
  position: absolute;
  top: 30px;
  left: 0;
  text-align: left;
  width: 260px;
  z-index: 100;
  background-color: #f6f6f6;
  box-shadow: 0rem 0.6rem 0.9rem rgba(0, 0, 0, 0.3);
  z-index: 9;
}
header ul.menu ul.sub-menu li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #c5c5c5;
}
header ul.menu li:first-child ul.sub-menu li:first-child {
  font-style: italic;
}
header ul.menu ul.sub-menu li:last-child {
  border-bottom: none;
}
header ul.menu ul.sub-menu li a {
  padding: 0.5rem 0.9rem 0.5rem 0.9rem;
  width: 100%;
  float: left;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
}
header ul.menu ul.sub-menu li:hover {
  background-color: #ececec;
}
header ul.menu ul.sub-menu li:hover a {
  text-decoration: none;
  color: #333333;
}

/*header .logo{
	top:15px;
	left:15px;
}*/

header ul.menu li.menu-item-has-children::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f107";
  top: 1px;
  position: relative;
}

header form button {
  background: none;
  border: 0;
  cursor: pointer;
}

#revista .bg-item,
#agenda .bg-item {
  padding-bottom: 55%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #cecece;
}

#article h1#title::before,
#autor h1::before,
#llibre h1::before,
#agenda h1#title::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f104";
  padding-right: 5px;
}

#article {
  .content {
    /*img{
			max-width: 100%;
			height: auto;
			display: block;
		}*/
    h1 {
      font-size: 1.5rem;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.25rem;
    }
  }
}

#drop-cataleg .dropdown-menu {
  top: 75px !important;
  left: 50% !important;
  width: 260px;
  border: 0;
  background-color: #f6f6f6;
  margin-left: -130px !important;
  box-shadow: 0rem 0.6rem 0.9rem rgba(0, 0, 0, 0.3);
  transform: none !important;
  z-index: 99 !important;
}

#drop-cataleg .dropdown-menu a {
  padding: 0.5rem 0.9rem 0.5rem 0.9rem;
  width: 100%;
  float: left;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  color: #333333;
  font-family: "Montserrat", sans-serif !important;
  border-bottom: 1px solid #c5c5c5;
}

#drop-cataleg .dropdown-menu a:last-child {
  border: 0;
}
#drop-cataleg .dropdown-menu a:hover {
  background-color: #ececec;
}

#drop-cataleg .dropdown-toggle {
  text-decoration: none;
}

/*** MAIN ***/

main {
  background-color: #f3f3f3;
  padding-bottom: 3rem;
}

.book {
  width: 100%;
  padding-bottom: 150%;
  // background-color: #cacaca;
  background-color: #949494;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.cover {
  box-shadow: 0rem 0.3rem 0.6rem rgba(0, 0, 0, 0.8) !important;
}

#nav_search_social form i {
  cursor: pointer;
  z-index: 99 !important;
  position: relative;
}
#nav_search_social form input {
  display: none;
  position: absolute;
  width: 270px;
  height: 25px;
  box-shadow: none;
  border-color: transparent;
  top: -3px;
  z-index: 9;
  text-align: left !important;
  padding-left: 25px !important;
  font-family: "Montserrat", sans-serif;
}

#content-book a[href] {
  color: #c33e3e !important;
  text-decoration: none;
}
#content-book a[href]:hover {
  text-decoration: underline;
}

.premis-content p {
  margin-bottom: 5px;
}

.premis p {
  margin: 0 !important;
}

/* MAP */
.acf-map {
  padding-bottom: 100%;
  border-radius: 100%;
  width: 100%;
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}

.btn,
.btn:focus {
  // box-shadow: none;
  // background-color:#e9cfcf !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  color: #ec0928;
}
.btn:hover {
  /*background-color: transparent !important;*/
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  color: #ec0928;
}

.rounded-col,
.square-col,
.rect-col {
  width: 100%;
  padding-top: 100%;
  border-radius: 100% !important;
  background-color: #d1d1d1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.square-col {
  border-radius: 0 !important;
}
.rect-col {
  padding-top: 56.25%;
  border-radius: 0 !important;
}

#social #instaLayer {
  width: 100%;
  height: 480px;
  border: 1px solid #ecedef;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
}
#social #instaLayer a img {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

.iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.35%;
}
.iframe-wrapper iframe {
  width: 100%;
  position: absolute;
  height: 100%;
}

.a2a_default_style:not(.a2a_flex_style) a {
  float: none !important;
  display: inline-block !important;
}

/*** BOOTSTRAP CAROUSEL HOME ***/

#slide-main .back-img {
  height: 375px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#slide-main .carousel-item .row {
  height: 375px;
}
#slide-main .carousel-indicators {
  bottom: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
#slide-main .carousel-item a {
  color: #fff;
}

.carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.5);
  width: 20px;
  height: 6px;
  border-bottom: 0;
}
.carousel-indicators li.active {
  background-color: #fff;
}

/*** CUSTOM CONTACT FORM 7 ***/

#columna-simple .wpcf7-form input {
  background-color: #e7e7e7;
  height: 45px;
  // display: inline-block;
  // float: left;
}
#columna-simple .wpcf7-form textarea,
#commentform textarea,
#commentform input {
  height: 100px !important;
  resize: none;
  background-color: #e7e7e7 !important;
}
#columna-simple .wpcf7-form-control-wrap.your-message {
  width: 100%;
}
#commentform textarea,
#commentform input {
  width: 100% !important;
  border: 0 !important;
  padding: 15px;
}
#commentform input {
  height: 45px !important;
}
#commentform input[type="submit"] {
  border: 0 !important;
  background-color: #c33e3e !important;
  color: #fff !important;
  padding: 15px !important;
  cursor: pointer !important;
  height: auto !important;
  width: auto !important;
}
.commentlist .comment {
  margin-top: 35px !important;
}

// #columna-simple .wpcf7-form input[type=file]{
// 	height: auto;
// 	width: auto;
// 	display: initial;
// 	float: none;
// }

#columna-simple .wpcf7-form input[type="submit"] {
  width: 45px;
  height: 45px;
  // right:15px;
  // top:0;
  background-color: #c33e3e !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  // float: right;
}
// #columna-simple .wpcf7-form-control-wrap{
// 	margin-bottom: 15px;
// 	float: left;
// 	display: inline-block;
// 	width: auto;
// 	width: 50%;
// }

// #columna-simple .wpcf7-form-control-wrap[class*="acceptance"]{
// 	width: 80% !important;
// }

#newsletter {
  background-color: #e7e7e7;
}
#newsletter .wpcf7-form input[type="email"] {
  height: 45px;
  background-color: #dbdbdb;
}
#newsletter .wpcf7-form input[type="submit"] {
  width: 45px;
  height: 45px;
  right: 15px;
  top: 0;
  background-color: #c33e3e !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}
#newsletter .wpcf7-response-output {
  font-size: 0.75rem !important;
  &.wpcf7-validation-errors {
    display: none !important;
  }
}

#columna-simple .wpcf7-form span.wpcf7-acceptance label {
  margin-right: 5%;
  line-height: 18px;
  text-align: left;
}
.wpcf7-form span.wpcf7-acceptance {
  font-size: 0.75rem;
}

.wpcf7-form input[type="checkbox"] {
  -webkit-appearance: none;
  background-color: transparent !important;
  border: 1px solid #000;
  position: relative;
  width: 0.75rem !important;
  height: 0.75rem !important;
  border-radius: 0.15rem;
  top: 1px;
  margin-right: 3px;
  float: none !important;
}

.wpcf7-form input[type="checkbox"]:checked::after {
  content: "\f00c";
  position: absolute;
  top: -3px;
  left: -1px;
  color: #282b2d !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.wpcf7-form span.wpcf7-list-item {
  margin: 0 !important;
  display: block;
}

.wpcf7-not-valid-tip,
.wpcf7-validation-errors {
  float: left;
  margin: 0 !important;
  font-size: 0.875rem !important;
}

.wpcf7-response-output {
  margin-bottom: 15px !important;
}

/*** FOOTER ***/

footer {
  line-height: 1.5rem;
}

footer .menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
footer .menu li {
  display: inline-block;
  float: none;
}
footer .menu li::after {
  content: ", ";
}
footer .menu li:last-child::after {
  content: "";
}
footer .menu li a {
  text-decoration: none;
}
footer .menu li a:hover {
  text-decoration: underline;
}

/* COOKIES BAR */

#cookie-notice {
  border-top: 1px solid #000;
  font-family: "Merriweather", serif !important;
}
#cookie-notice .cookie-notice-container {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  width: 100%;
  margin: auto;
}
#cookie-notice .cookie-notice-container .cn-button {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  text-shadow: none;
  background-image: none;
  border-radius: 0;
  border: 0;
  padding: 2px 10px;
  box-shadow: none;
  margin-right: 5px;
  margin-left: 5px;
  letter-spacing: 0.03em;
  font-size: 0.7rem;
  text-transform: uppercase;
  background-color: #3e3e3e;
  text-decoration: none;
}

#cookie-notice .cookie-notice-container .cn-button.cn-set-cookie {
  background-color: #ec0928 !important;
}

#cookie-notice .cn-text-container {
  width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.opaquer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.7;
}
.back {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  background-size: cover;
  background-position: center;
}

.column-2 {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 3em;
  column-gap: 3em;
}

@media (max-width: 767.98px) {
  .column-2 {
    -webkit-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1em;
    column-gap: 1em;
  }
}

/*==========  Bootstrap 4 Breakpoints Responsive Non-Mobile First Method  ==========*/

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  header ul.menu > li {
    padding: 0.3rem 0.5rem;
  }
  #nav_search_social form input {
    width: 112px !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {
  header .logo {
    top: 0 !important;
    width: 100%;
    left: auto;
  }
  /*header .logo img{ width: 40px !important; }*/

  #slide-main .carousel-indicators {
    bottom: -5px;
  }

  #cookie-notice .cookie-notice-container {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  header .logo {
    top: 15px !important;
  }
  // header .logo img{ width: 65px !important; }
  header .navbar-toggler {
    bottom: 20px;
    left: calc(50% - 15px);
  }
  header #bar {
    /*height: 130px;*/
    width: 100%;
  }
  header #nav_search_social {
    margin-top: 95px;
    background-color: #fff;
    padding-bottom: 15px;
    max-height: calc(100vh - 130px);
    overflow-y: scroll;
    overflow: hidden;
  }
  header .nav,
  header .nav > div {
    width: 100%;
  }
  header ul.menu > li {
    width: 100%;
    padding: 0.6rem 0.6rem;
  }
  header ul.menu ul.sub-menu {
    position: static;
    width: 100%;
    text-align: center;
  }
  #nav_search_social form input {
    position: relative !important;
    display: inline-block;
    width: 50%;
    text-align: right !important;
  }
  #nav_search_social form i {
    top: 0px;
    margin-right: 40% !important;
    text-align: left;
  }
  #nav_search_social i {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
  #slide-main .back-img,
  #slide-main .carousel-item .row {
    height: 100vh;
  }
  #slide-main .fs24 {
    font-size: 1.125rem;
  }

  // #columna-simple .wpcf7-form-control-wrap{
  // 	width:100%;
  // }
  // #columna-simple .wpcf7-form input,#columna-simple .wpcf7-form textarea{
  // 	width:100%;
  // }
  .acf-map {
    position: relative !important;
    bottom: auto;
    right: auto;
    padding-bottom: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}
